import { ComponentFactory, createContext, h } from 'preact';
import {
	ContentBannerItem,
	ContentButtonItem,
	ContentContainer,
	ContentExtras,
	ContentHTMLItem,
	ContentImageItem,
	ContentItem,
	ContentMenuBlockItem,
	ContentProductSetBlockItem,
	ContentTemplate,
} from '../../common/data/content';
import { Maybe, PublicDomain } from '../../common/data';
import { ProductionTimeFaqTable, SizeChartFaqTable } from './faq-page';
import { useContext } from 'preact/compat';
import { apply, maybe } from '../../common/util';
import { JSXInternal } from 'preact/src/jsx';
import { ProductCard } from './product-card';
import { getContentUrlPrefix } from '../lib/images';
import { Email } from '../../common/data/email';
import dayjs from 'dayjs';

export interface ContentContextData {
	target: ('web' | 'email' | 'html' | 'plaintext' | 'faq' | 'email-campaign' | 'email-trx' | 'email-newsletter')[];
	domain: PublicDomain;
	website: string;
	extras: ContentExtras;
	email?: Maybe<Email>;
	link: (href: string) => string;
	image: (path: string) => string;
	templates: Record<string, ContentTemplateItemRederer>;
	unsubscribeLink?: string;
	staticsSet?: Maybe<Set<string>>;
}
export const ContentContext = createContext<ContentContextData>({
	target: [],
	domain: {} as any,
	website: '',
	extras: {} as any,
	email: null,
	templates: {},
	link: s => s,
	image: s => s,
});

export type ContentContextDataInit = Omit<ContentContextData, 'website'> & {
	website?: Maybe<string>;
	protocol?: Maybe<string>;
};
export const initContentContext = (context: ContentContextDataInit) => ({
	...context,
	website: context.website ?? `${context.protocol ?? 'https://'}${context.domain.host}`,
});

function ContentRows({ item }: { item: ContentContainer }) {
	return <div class="content-item__rows" style={item.css}>
		{item.children.map(c => <ContentBlock item={c} />)}
	</div>;
}

function ContentColumns({ item }: { item: ContentContainer }) {
	const wsum = item.children.reduce((a, child) => a + (child.size || 1), 0);
	return <div class="content-item__columns" style={item.css}>
		{item.children.map(c => apply(
			Math.floor((c.size || 1) / wsum * 100),
			width => <ContentBlock item={c} style={{ flex: `1 1 ${width}%`, width: `${width}%` }} />
		))}
	</div>;
}

function ContentGrid({ item }: { item: ContentContainer }) {
	return <div class="content-item__grid" style={{ ...item.css, gridTemplateColumns: `repeat(${item.gridColumns}, 1fr)` }}>
		{item.children.map(c => <ContentBlock
			item={c}
			style={(c.size || 1) > 1 ? { gridColumn: `auto / span ${c.size}` } : {}}
		/>)}
	</div>;
}

function ContentHtml({ item }: { item: ContentHTMLItem }) {
	
	const { target } = useContext(ContentContext);
	
	if (target.includes('email')) return <div
		style={{ padding: '16px' }}
		dangerouslySetInnerHTML={{ __html: item.html }}
	/>;
	
	return <div class="content-item__html" dangerouslySetInnerHTML={{ __html: item.html }} style={item.css}/>;
	
}

function ContentImage({ item }: { item: ContentImageItem }) {
	if (!item.src) return <div />;

	const imgStyle = { ...(item.width ? { width: item.width } : {}), ...(item.height ? { height: item.height } : {}) };
	item.style = item.style ?? 'center';

	return <div class={`content-item__image image-${item.style}`} style={item.css}>
		<div>
			{item.link ? <a href={item.link}><img src={item.src} style={imgStyle}/></a> : <img src={item.src} style={imgStyle}/>}
			{item.info && <div className="content-item__image-info">{item.info}</div>}
		</div>
	</div>;
}

function ContentBanner({ item }: {item: ContentBannerItem}) {
	const imgStyle = { ...(item.width ? { width: item.width } : {}), ...(item.height ? { height: item.height } : {}) };
	const overlayStyle = { ...(item.fontColor ? { color: item.fontColor } : {}), textShadow: `${item.textShadowX}px ${item.textShadowY}px ${item.textShadowBlur}px ${item.textShadowColor}` };
	
	if (!item.src) return <div />;
	
	item.style = item.style ?? 'center';
	const overlay = item.info ? <div class="content-item__banner-text" style={overlayStyle}>{item.info}</div> : null;
	
	return <div class={`content-item__banner image-${item.style}`} style={item.css}>
		<div class="content-item__banner-overlay">
			{item.link ? <a href={item.link}><img src={item.src} style={imgStyle} />{overlay}</a> : <><img src={item.src} style={imgStyle} />{overlay}</>}
		</div>
	</div>;
}

function ContentButton({ item }: {item: ContentButtonItem}) {

	const { target, website } = useContext(ContentContext);
	
	if (target.includes('email')) return <div width="100%" {...{ align: 'center' }}>
		<a href={item.link}>
			<img style="cursor: pointer; border: 0" src={`${website}/static/emails/common/buttons/learn_more.png`} />
		</a>
	</div>;
	
	item.variant = item.variant ?? 'regular';
	item.style = item.style ?? 'center';
	
	return <div style={{ textAlign: item.style, ...item.css }}>
		<a className={`content-item__button-${item.variant}`} href={item.link}>{item.info}</a>
	</div>;
	
}

function ContentSizeChart() {
	return <div className="content-item__html">
		<SizeChartFaqTable />
	</div>;
}

function ContentProdTime() {
	return <div className="content-item__html">
		<ProductionTimeFaqTable />
	</div>;
}

export type ContentTemplateItemRederer<T = any> = ComponentFactory<{ item: ContentTemplate<T> }>;

function ContentTemplateBlock({ item }: { item: ContentTemplate }) {
	const template = useContext(ContentContext).templates[item.template];
	if (!template) return <div dangerouslySetInnerHTML={{ __html: `<!-- Unknown template: ${item.template} -->` }} style={item.css} />;
	return h(template, { item });
}

function ContentProductSetBlock({ item }: {item: ContentProductSetBlockItem }) {
	
	const { extras, domain } = useContext(ContentContext);
	const { products, categories } = extras;
	
	if (!products?.length) return <></>;

	const overrideCategories = categories.filter(cat => item.categories.includes(cat.id) );
	const filteredProducts = products.filter(p => item.categories.filter(c => p.categories.includes(c)).length > 0 );
	
	return <div className="content-item__productset">
		<div className="content-item__productset-category" style={item.css}>
			{filteredProducts?.map(p => <div className="content-item__productset-column"><ProductCard
				product={p}
				overrideCategories={overrideCategories}
				domain={domain}
				categories={categories}
			/></div>)}
		</div>
	</div>;
}

function ContentMenuBlock({ item }: {item: ContentMenuBlockItem }) {
	
	const { extras, domain } = useContext(ContentContext);
	const { menus } = extras;
	
	const menu = menus?.[item.menuId];
	if (!menu) return <></>;
	const { entries } = menu;
	if (!entries) return <></>;
	
	if (!item.style || item.style == 'icon-grid') return <div className="content-item__menu-icon-grid" style={item.css}>
		{entries.map( e => <a href={`${e.link}`} className="content-item__menu-icon-grid-category">
			<div className="content-item__menu-icon-grid-icon" style={{ backgroundImage: `url('/data/menu/${e.image}')` }}></div>
			<div className="content-item__menu-icon-grid-title">{e.title?.toUpperCase()}</div>
		</a>)}
	</div>;
	
	if (item.style == 'footer') {
		const linkMenus = entries.slice(0, 3);
		const buttonMenu = entries[3];
		return <div className="content-item__menu-footer" style={item.css}>
			<div className="footer">
				<div className="footer__row">
					<div className="footer__column footer__column--brand">
						<img className="footer__logo" src={`${getContentUrlPrefix()}/static/${domain.id}/logo-footer.svg`}/>
					</div>
					{linkMenus.map( menu => <div className="footer__column footer__column--links">
						{menu.title && <h3 className="footer__column-heading">{menu.title}</h3>}
						{maybe(menu.entries, entries => <ul className="footer-navbar">
							{entries.map(submenu => <li className="footer-navbar__item">
								<a href={submenu.link} className="footer-navbar__link">{submenu.title}</a>
							</li>)}
						</ul>)}
					</div>)}
					<div className="footer__column footer__column--buttons">
						{buttonMenu?.entries?.map( submenu => <a href={submenu.link} className="footer__button">{submenu.title}</a>)}
					</div>
				</div>
				<p class="footer__copyright">&copy; {dayjs().format('YYYY')} {domain.brand_name}. All rights reserved.</p>
			</div>
		</div>;
	}
	
	return <></>;
}

const blocks: Record<string, any> = {
	init: () => undefined,
	size_chart: () 						=> <ContentSizeChart />,
	prod_time: () 						=> <ContentProdTime />,
	rows: (item: ContentItem) 			=> <ContentRows 				item={item as ContentContainer}/>,
	columns: (item: ContentItem) 		=> <ContentColumns 				item={item as ContentContainer}/>,
	grid: (item: ContentItem) 			=> <ContentGrid 				item={item as ContentContainer}/>,
	html: (item: ContentItem) 			=> <ContentHtml 				item={item as ContentHTMLItem}/>,
	image: (item: ContentItem) 			=> <ContentImage				item={item as ContentImageItem}/>,
	banner: (item: ContentItem) 		=> <ContentBanner 				item={item as ContentBannerItem}/>,
	button: (item: ContentItem) 		=> <ContentButton 				item={item as ContentButtonItem}/>,
	template: (item: ContentItem) 		=> <ContentTemplateBlock 		item={item as ContentTemplate} />,
	productset: (item: ContentItem) 	=> <ContentProductSetBlock 		item={item as ContentProductSetBlockItem}/>,
	menu: (item: ContentItem) 			=> <ContentMenuBlock			item={item as ContentMenuBlockItem}/>,
};

export function ContentBlock({ item, style = {}, width }: { item: ContentItem; style?: JSXInternal.CSSProperties; width?: number }) {
	const itemToRender = blocks[item.type]?.(item);
	const isTemplate = item.type === 'template';

	if (itemToRender && !isTemplate) return <div class="content-item" style={{ ...style, ...(item.backgroundColor ? { backgroundColor: `${item.backgroundColor}` } : {}), ...(width ? { flex: `1 1 ${width}%`, width: `${width}%` } : {}) }}>
		{itemToRender}
	</div>;

	if (itemToRender && isTemplate) return itemToRender;

	return <div />;
}

// export function ContentWithHeader({ content }: { content: Content }) {
// 	const bgStyle: any = { backgroundColor: content.img_display?.backgroundColor || '#fff' };
// 	if (content.img_display?.size == 'cover') {
// 		bgStyle.backgroundImage = `url(/data/content/${content.header_img})`;
// 		bgStyle.backgroundPosition = `${content.img_display.horizontal || 'center'} ${content.img_display.vertical || 'center'}`;
// 	}
//
// 	return <Fragment>
// 		<div class={`content-page__with-header__head-background`} style={bgStyle} />
// 		<div class={`content-page__with-header__head-image`}>
// 			{content.img_display?.size != 'cover' && content.header_img && <img src={`/data/content/${content.header_img}`} />}
// 		</div>
// 		<div class={`content-page__with-header__body`}>
// 			<ContentBlock item={content.content} />
// 		</div>
// 	</Fragment>;
// }

// export class ContentPage extends Component<ContentWithExtras> {
//
// 	render({ content, extras }: ContentWithExtras) {
// 		return <div class={`content-page content-page__${content.mode}`}>
// 			<ContentContext.Provider value={extras}>
// 				{content.mode === 'with-header' ? <ContentWithHeader content={content} /> : <ContentBlock item={content.content} />}
// 			</ContentContext.Provider>
// 		</div>;
// 	}
//
// }
