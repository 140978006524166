import { useState } from 'preact/hooks';
import { CartIconAlt } from './icons';
import { addGiftcardToCart } from '../lib/api';
import { Input } from './input';
import { showAlert } from './popup';
import { PublicDomain } from '../../common/data';

export function GiftcardOrderPage({ domain }: {
	domain: PublicDomain;
}) {
	
	const [amount, setAmount] = useState(70);
	
	const presets = [20, 100, 200];
	
	async function addToCart(amt: number) {
		const res = await addGiftcardToCart({ amount: amt });
		if (res.error) await showAlert(res.error);
		if (res.id) location.href = '/cart';
	}
	
	return <div class="giftcard-order">
	
		<h1>Gift cards</h1>
		
		<p>
			Purchase a reusable gift card valid for any {domain.brand_name} products or services.
		</p>
		
		<div class="giftcard-order__options">
			{presets.map(amt => <div class="giftcard-order__option">
				<div class="giftcard-order__option-amount">${amt}</div>
				<button class="giftcard-order__option-add-to-cart" onClick={() => addToCart(amt)}>
					<CartIconAlt/>
					Add to cart
				</button>
			</div>)}
			<div class="giftcard-order__option">
				<div class="giftcard-order__option-custom-amount">
					<span>$</span>
					<Input value={amount} handler={value => setAmount(Number(value) || 70)} />
				</div>
				<button class="giftcard-order__option-add-to-cart" onClick={() => addToCart(amount)}>
					<CartIconAlt />
					Add to cart
				</button>
			</div>
		</div>

		<p>
			You will receive a short code that you can share with the recipient in any way you like.
		</p>
		
	</div>;
	
}
