import { PublicDomain } from '../data';

function numberForm(n: number) {
	return n == 1 ? 0 : 1;
}

export const en = {

	numberForm,

	toIndex: 'Return',

	yourEmail: 'Email address',

	restoreEmailSubject: (brand: string) => `Your ${brand} password restore link`,
	restoreEmailText: ({ token, domain }: { token: string; domain: PublicDomain }) => `Hi,

Someone requested a link to restore password for ${domain.brand_name} account with this email address.
If it wasn't you, you can probably ignore this email. Otherwise, here it is:

https://${domain.host}/email-token/${token}

This link will expire in 24 hours.

See you there,
${domain.brand_name}
`,

};
