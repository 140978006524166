import { Component } from 'preact';
import { ProductCategory, PublicProduct, StoreTier } from '../../common/data';
import { formatPrice, num } from '../../common/util';

export interface CommissionsPageProps {
	tiers: StoreTier[];
	products: PublicProduct[];
	categories: ProductCategory[];
}

export class CommissionsPage extends Component<CommissionsPageProps> {
	constructor(props: CommissionsPageProps) {
		super(props);
	}
	
	getPrice(pprice: string|number, cid: string, kind: string, tier: StoreTier) {
		const discount = num(tier.discounts[`${cid}:${kind}`] ?? tier.discounts[`${cid}:public`] ?? tier.default_discount ?? 0);
		return formatPrice(Math.round(Number(pprice) * (100 - discount)) / 100);
	}
	
	render(props: CommissionsPageProps) {
	
		const blocks = props.categories.flatMap(category => {
			const { id } = category;
			const products = props.products.filter(p => p.main_category === id);
			const haveCustom = props.tiers.some(tier => tier.discounts[`${id}:custom`]);
			const kinds = haveCustom ? ['public', 'custom'] : ['public'];
			return kinds.map(kind => ({ id, category, haveCustom, kind, products }));
		}).filter(block => block.products.length);
		
		return <div class="commissions">
			<div class="commissions__container">
				<div class="commissions__table-responsive">
					<table class="commissions__table">
						<thead>
							<tr>
								<th>PRODUCTS</th>
								{props.tiers.map((tier) => <th>{tier.name.toUpperCase()}</th>)}
							</tr>
						</thead>
						<tbody>
							{blocks.map(({ id, category, haveCustom, kind, products }) => [
								<tr>
									<th colSpan={2 + props.tiers.length}>{category.name} {haveCustom ? (kind == 'custom' ? ` (Custom designs)` : ` (Standard designs)`) : ''}</th>
								</tr>,
								products.map(p => <tr>
									<td class="commissions__table-name">{p.name}</td>
									{
										props.tiers.map((tier) => <td>
											{this.getPrice(p.price!, id, kind, tier)}
										</td>)
									}
								</tr>),
								<tr>
									<td colSpan={2 + props.tiers.length}>&nbsp;</td>
								</tr>,
							])}
						</tbody>
					</table>
				</div>
			</div>
		</div>;
	}
	
}
