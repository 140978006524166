import { ProductCategory, PublicDomain, PublicProduct } from '../../common/data';
import { formatPrice, isSome, maybe } from '../../common/util';
import { ColorfullSquareIcon } from './icons';
import { Picture } from './image';
import { memo } from 'preact/compat';

export const ProductCard = memo(function ProductCard({ product, overrideCategories, domain, categories }: {
	product: PublicProduct;
	overrideCategories: ProductCategory[];
	domain: PublicDomain;
	categories: ProductCategory[];
}) {
	
	const getOverride = (field: 'name' | 'preview') => product.overrides?.find(override => (
		(!override.category || overrideCategories.some(cat => cat.id == override.category))
		&& !override.product_template // just ignore these here
		&& isSome(override[field])
	))?.[field] ?? product[field];
	const name = getOverride('name');
	const preview = getOverride('preview');
	
	const colorIcon = (typeof window == 'object') ? <ColorfullSquareIcon /> : '';
	
	const designOrColors = product.is_premade ? 'Color' : 'Design Template';
	
	return <div
		class="product-card"
		style={{ '--product-aspect-ratio': product.aspect_ratio }}
		itemType="https://schema.org/Product"
		itemScope
	>
		
		<link itemProp="image" href={`https://${domain.host}/data/products/${preview}`} />
		
		<div class="product-card__inner">
		
			<span class="product-card__color-icon"><a
				class="product-card__color-icon-link"
				href={`/builder?product=${product.id}`}
			>{colorIcon}</a>
			
			<a
				class="product-card__color-icon-after"
				href={`/builder?product=${product.id}`}
			>Customize</a></span>
			
			<div class="product-card__image">
				<a class="product-card__image-inner" href={`/builder?product=${product.id}`}>
					<Picture
						class="product-card__image-img"
						src={`/data/products/${preview}`}
						loading="lazy"
					/>
					<Picture
						class="product-shadow"
						src={`/data/products/${product.preview?.replace('.png', '')}.shadow.png`}
						loading="lazy"
					/>
				</a>
			</div>
			
			<div class="product-card__bottom">
				<a class="product-card__customize" href={`/builder?product=${product.id}`}>Customize</a>
				<p itemProp="name" class="product-card__bottom-row" title={name}>{name}</p>
				{/*product.main_category && <p
					class="product-card__bottom-row"
					title={getCategoryName(product.main_category)}
				>{getCategoryName(product.main_category)}</p>*/}
				{product.pd_count && <p
					class="product-card__bottom-row"
					title={`Available ${designOrColors}s`}
				>{`${product.pd_count} ${product.pd_count > 1 ? `${designOrColors}s` : `${designOrColors}`}`}</p>}
				<p
					itemProp="offers"
					itemType="https://schema.org/Offer"
					itemScope
				>
					<meta itemProp="availability" content="https://schema.org/InStock"/>
					<span
						class="product-card__bottom-row"
						itemProp="priceSpecification"
						itemScope
						itemType="https://schema.org/UnitPriceSpecification"
					>
						<meta itemProp="price" content={String(product.price)} />
						<meta itemProp="priceCurrency" content="USD"/>
						<span itemProp="eligibleQuantity" itemType="https://schema.org/eligibleQuantity" itemScope>
							<meta itemProp="minValue" content={String(product.min_order_quantity || 1)} />
						</span>
						{maybe(product.price_range, pr => <>
							<span itemProp="minPrice" content={String(pr[0])}>{formatPrice(pr[0])}</span>
							{' - '}
							<span itemProp="maxPrice" content={String(pr[1])}>{formatPrice(pr[1])}</span>
						</>)}
						{!product.price_range && maybe(product.price, formatPrice)}
					</span>
				</p>
				<p class="product-card__bottom-row">
					{maybe(
						product.min_order_quantity,
						moq => moq > 1 ? <span>{moq} Piece Minimum Order</span> : null,
					) ?? 'No Minimum Order'}
				</p>
			</div>
			
		</div>
	</div>;
});
