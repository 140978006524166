import { setCookie } from '../../common/util';

export async function recoverLegacyAuth(domain: string) {
	
	const res = await fetch(`https://${domain}/legacy/auth`, {
		credentials: 'include',
	});
	if (!res.ok) return;
	
	const cookies = await res.json();
	
	setCookie('auth', cookies.auth || 'void');
	if (cookies.session) setCookie('session', cookies.session);
	location.reload();
	
}
