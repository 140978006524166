import { showAlert } from './popup';
import { Picture } from './image';

const customProcessArticles = [
	{ title: 'vector graphics', description: 'If you are applying your custom logo to your custom esports jersey then we will need high quality graphics. We require <a href="/raster-vs-vector-images/">vector graphics</a> for all jobs. Vector graphics differ from raster (pixelated) graphics in that you are able to scale vector graphics infinitely without losing resolution. This is useful for many applications, not just gaming jerseys. We provide a service to vectorize the logo for you. Upload your highest resolution image and we can get started.' },
	{ title: 'gaming jersey design', description: 'Once you have your logo as a <a href="/raster-vs-vector-images/">vector graphics</a> or if you are placing an order without your custom logo, then you can move on to your <a href="/products?_ga=2.155758331.1561969319.1666464178-1854417174.1664556676">custom esports jersey design!</a> To start, you can apply your custom colors, move them to different locations of the shirt, and choose which you believe looks the best. Once you\'ve completed your design you can add the rest of your team to the roster, automatically receive bulk ordering discounts, and place your order.' },
	{ title: 'printing', description: `Once your order is placed we prepare it for the printer. We use a large format dye sublimation printer to print directly onto, basically, a sheet of paper. One key benefit of using this method is you are not limited by colors. You are able to use any color possible in your custom esports jersey design! After the shirt is printed to transfer paper, it is heat applied to the blank, white, polyester fabric that makes up the fabrication of the gaming jersey. The ink is most literally pressed into the fabric. This is why the jerseys are so vibrant!` },
	{ title: 'cut & sew', description: `This step is exactly as it sounds. We now have something that doesn't look like a gaming jersey at all. We have moisture management fabric with your custom esports jersey pressed into it. From there, we cut the shirt to shape and sew it together, package it, and ship it out.` },
];

const customEsportsArticles = [
	{ title: 'gaming lifestyle', description: 'Gaming, for years, has carried with it a certain stigma. It spawned from a misunderstood pass time in a technology driven generation. Now, video games are celebrated at the highest level of competition, streamed from personal offices and living rooms from around the globe, and played by a majority of the population. A gaming apparel item isn\'t just for an esports stage, team, or stream. It\'s for school, hanging out with friends, wearing around the house, running errands, or any other every day event.' },
	{ title: 'custom esports jerseys', description: 'Playmakers Wanted is a brand that celebrates those pivotal gaming moments the same way NFL fans roar over a key interception, NBA fans shout for a deep three-pointer, and hockey fans scream for an amazing save. Wear your team colors and logo proudly, vibrantly, and with pro designs for your team\'s custom esports jerseys.' },
	{ title: 'commemorate', description: 'Some games stick with us, but more importantly the players we shared those games with. Don\'t miss out on an opportunity to create a memento of the game you played and the people you played with. Custom esports jerseys or other merch are the perfect way to remember the experience for years to come.' },
];

export function LandingPage() {
	
	async function showModal(e: Event) {
		const src = (e.target as HTMLImageElement).src;
		await showAlert(<img src={src} alt="modal-photo" style={{ width: '100%' }} />, 'info');
	}
	function showModalOnKey(e: KeyboardEvent) {
		if (e.key == 'Enter') showModal(e);
	}

	return <div class="landing">
		<div class="landing__hero">
			<div class="landing__title-wrapper">
				<h1 class="landing__title">Welcome, gamer.</h1>
				<p class="landing__subtitle">Ready to design your custom esports jersey?</p>
			</div>
			
			<a class="landing__button" href="/products?type=jerseys">Get started</a>
		</div>
		
		<div class="landing__store">
			<div class="landing__store-row">
				<Picture class="landing__store-img" src="/static/landing/stores.png" webp="/static/landing/stores.webp" alt="stores" />
				<div class="landing__col">
					<ol class="landing__store-instruction">
						<li>Create a free store</li>
						<li>Sell your merch</li>
						<li>Profit</li>
					</ol>
					<a className="landing__button" href="/video-game-merchandise/">create your store</a>
				</div>
			</div>
			<div class="landing__aqua-line">
				<p>Upload, customize, and open your online</p>
				<p>Store to earn cash & rewards now!</p>
			</div>
		</div>
		
		<div class="landing__team-apparel">
			<div class="landing__green-line">
				<p>One of a kind</p>
				<p>team apparel.</p>
			</div>
			
			<a className="landing__button landing__button_apparel" href="/products?type=drinkware,headwear,merchandise,mousepad,t-shirts">design now</a>
		</div>
		
		<div class="landing__design-packages">
			<Picture src="/static/landing/t-shirts.png" webp="/static/landing/t-shirts.webp" class="landing__jerseys" alt="jerseys" />
			<div class="landing__design-packages-col">
				<h3 class="landing__design-packages-title">Looking for something completely unique?</h3>
				
				<div class="landing__purple-line">
					<div class="landing__design-packages-subtitle">
						<p>Check out our</p>
						<p>Design packages</p>
					</div>
					
					<a class="landing__button landing__button--pckges" href="/design-packages/">Click here</a>
				</div>
			</div>
		</div>
		
		<div className="landing__custom">
			<h2 className="landing__custom-title">custom esports jerseys</h2>
			<h4 class="landing__custom-subtitle">more than a jersey</h4>
			<div className="landing__custom-articles">
				{customEsportsArticles.map(({ title, description }) => <div className="landing__custom-article">
					<h3 className="landing__custom-article-title">{title}</h3>
					<p className="landing__custom-article-description" dangerouslySetInnerHTML={{ __html: description }}/>
				</div>)}
			</div>
			
			<a class="landing__custom-button" href="/builder">Customize</a>
		</div>
		
		<div class="landing__quotes">
			<div class="landing__quote">
				<p class="landing__quote-text">"I spent two weeks playing with designs on other sites and getting frustrated. Playmakers Wanted was by far the best gaming jersey company."</p>
				
				<div class="landing__quote-row">
					<p class="landing__quote-author">RedEye_DeadEye</p>
					<p class="landing__quote-regalia">C.O.O. proesportsgaming.com</p>
				</div>
				<button className="landing__quote-btn landing__quote-btn--prev">«</button>
				<button className="landing__quote-btn landing__quote-btn--next">»</button>
			</div>
		</div>
		
		<div class="landing__photos">
			{[...Array(13).keys()].slice(1).map(i => <img
				class="landing__photo"
				loading="lazy"
				src={`/webp/static/landing/photos/${i}.jpeg.webp`}
				alt="Customer photo"
				tabIndex={0}
				role="button"
				onClick={showModal}
				onKeyDown={showModalOnKey}
			/>)}
		</div>
		
		<div class="landing__custom">
			<h2 class="landing__custom-title">Custom gaming jersey process</h2>
			<div class="landing__custom-articles">
				{customProcessArticles.map(({ title, description }) => <div class="landing__custom-article">
					<h3 class="landing__custom-article-title">{ title }</h3>
					<p class="landing__custom-article-description" dangerouslySetInnerHTML={{ __html: description }} />
				</div>)}
			</div>
		</div>
	</div>;
}
