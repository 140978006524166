import { Component } from 'preact';
import { Maybe, PublicFlow, PublicProduct, RosterEntry, Store, StoreProduct } from '../../common/data';
import { apply, formatPrice, throttle } from '../../common/util';
import { addStoreProductToCart } from '../lib/api';
import { CartIconAlt } from './icons';
import { getStoreUrl, nl2p } from '../lib/util';
import { showAlert } from './popup';
import { getSizeName } from '../../common/data/products';
import { Input } from './input';
import { CountryPicker } from './country';
import { defaultRosterEntry } from '../../common/data/roster';
import { DesignRosterFields, ProductTemplate, rosterPartTypes } from '../../common/data/designs';
import { DesignPicture } from './image';

export interface StoreProductProps {
	storeProduct: StoreProduct;
	store: Store;
	product: PublicProduct;
	flow: PublicFlow;
	productTemplates: ProductTemplate[];
	rosterFields: DesignRosterFields;
}

export interface StoreProductState {
	previewSide: string;
	size?: string;
	productTemplate?: string;
	quantity: number;
	name?: string;
	alias?: string;
	number?: string;
	flag?: Maybe<string>;
	rosterEntry?: Maybe<RosterEntry>;
}

export class StoreProductPage extends Component<StoreProductProps, StoreProductState> {

	constructor(props: StoreProductProps) {
		super(props);
		
		this.state = {
			previewSide: 'front',
			quantity: 1,
		};
		this.changeSize = this.changeSize.bind(this);
		this.addToCart = this.addToCart.bind(this);
		this.changeColor = this.changeColor.bind(this);
	}
	
	async inputHandler(key: keyof RosterEntry, value?: string) {
		await this.setState({ [key]: value });
		this.updateRosterEntry();
	}
	updateRosterEntry = throttle(async () => {
		const rosterEntry: RosterEntry = {
			id: 'preview',
			name: this.state.name ?? defaultRosterEntry.name,
			alias: this.state.alias ?? defaultRosterEntry.alias,
			number: this.state.number ?? defaultRosterEntry.number,
			flag: this.state.flag ?? defaultRosterEntry.flag,
		};
		await this.setState({ rosterEntry });
	}, 1000);

	render(props: StoreProductProps, state: StoreProductState) {
		console.log(props.storeProduct.options, props.productTemplates);
		const color = props.store.highlight_color || '#a4a4a4';
		return <div class="store-product" style={`--main-color: ${color}`}>
			<div class="store-product__main">
				<div class="store-product-content">
					<h1 class="store-product__heading"><a class="store-product__link" href={getStoreUrl(props.store)}>{props.store.name}</a></h1>
					<div class="store-product__content">
						<div class="store-product__column store-product__column--image">
							<div class="store-product-large">
								<DesignPicture
									class="store-product-large__img"
									designId={props.storeProduct.design}
									side={state.previewSide}
									rosterEntry={state.rosterEntry}
									productTemplate={state.productTemplate}
									previewWidth={1024}
								/>
							</div>
							<div class="store-product-small store-product-small--thumbnail">
								{props.product?.features?.sides?.map(
									side => <DesignPicture
										designId={props.storeProduct.design}
										side={side}
										rosterEntry={state.rosterEntry}
										productTemplate={state.productTemplate}
										previewWidth={96}
										onClick={() => this.setState({ previewSide: side })}
									/>
								)}
							</div>
						</div>
						<div class="store-product__column store-product__column--design">
							<div class="store-product-info">
								<h2 class="store-product-info__heading">{props.storeProduct.name}</h2>
								<h2 class="store-product-info__price">{formatPrice(props.storeProduct.price)}</h2>
								<div class="store-product-info__description">
									{nl2p(props.storeProduct.description?.trim() || props.product.description || '')}
								</div>
								<div class="store-product-info__description store-product-info__description--secondary">
									{props.storeProduct.description?.trim() && props.product.description?.trim && nl2p(props.product.description)}
									{apply(props.flow.min_prod_days, props.flow.max_prod_days, (min, max) => <p>
										This product is printed on demand.<br />
										{'Production time: '}
										<strong>{min == max ? max : `${min}-${max}`} days</strong>
										{' + shipping'}
									</p>)}
								</div>
							</div>
							<form method="post" class="store-product-form">
								{props.storeProduct.options?.allowSwitchingTemplate && props.productTemplates?.length > 1 && <div className="cart__item-options">
									<label class="store-product-form__label">color</label>
									<select
										class="store-product-form__select"
										value={this.state.productTemplate}
										onChange={this.changeColor}
									>
										<option value="">-- Select Color --</option>
										{props.productTemplates.map(
											(color, key) => <option key={key} value={color.id}>{color.name}</option>
										)}
									</select>
								</div>}
								
								{rosterPartTypes.some(key => props.rosterFields[key]) && <div class="store-product-form__entries">
									{props.rosterFields.name && <div class="store-product-form__group">
										<label class="store-product-form__label store-product-form__label--flex">
											<span>Name</span>
										</label>
										<Input
											attributes={{ className: 'store-product-form__input store-product-form__input--full-border' }}
											value={state.name || ''}
											handler={str => this.inputHandler('name', str)}
										/>
									</div>}
									{props.rosterFields.alias && <div class="store-product-form__group">
										<label class="store-product-form__label store-product-form__label--flex">
											<span>Alias</span>
										</label>
										<Input
											attributes={{ className: 'store-product-form__input store-product-form__input--full-border' }}
											value={state.alias || ''}
											handler={str => this.inputHandler('alias', str)}
										/>
									</div>}
									{props.rosterFields.number && <div class="store-product-form__group store-product-form__group--number">
										<label class="store-product-form__label store-product-form__label--flex">
											<span>Number</span>
										</label>
										<Input
											attributes={{ className: 'store-product-form__input store-product-form__input--full-border' }}
											value={state.number || ''}
											handler={str => this.inputHandler('number', str)}
										/>
									</div>}
									{props.rosterFields.flag && <div class="store-product-form__group">
										<label class="store-product-form__label store-product-form__label--flex">
											<span>Flag</span>
										</label>
										<CountryPicker
											key={`country_add`}
											onChange={country => this.inputHandler('flag', country?.id)}
											value={state.flag}
											attributes={{ class: 'store-product-form__select' }}
										/>
									</div>}
								</div>}
								<div class="store-product-form__options">
									{!((this.props.product?.features?.sizes?.length || 0) < 2) && <div class="store-product-form__group">
										<label class="store-product-form__label store-product-form__label--flex">
											<span>size</span>
											{/*<span class="store-product-form__question store-product-form__question--primary">?</span>*/}
										</label>
										<select
											class="store-product-form__select"
											value={this.state.size}
											onChange={this.changeSize}
										>
											<option value="">-- Select Size --</option>
											{props.product?.features?.sizes?.map(
												(size, key) => <option key={key} value={size}>{getSizeName(size)}</option>
											)}
										</select>
									</div>}
									<div class="store-product-form__group">
										<label class="store-product-form__label store-product-form__label--flex">
											<span>Quantity</span>
										</label>
										<div class="store-product-form__group store-product-form__group--design">
											<button
												type="button"
												class="store-product-form__btn store-product-form__btn--decrease"
												id="btn_decrease"
												onClick={() => this.setState({ quantity: Math.max(1, state.quantity - 1) })}
											>
												<svg viewBox="0 0 25 25" class="store-product-form__svg-icon">
													<line x1="6" y1="12.5" x2="19" y2="12.5" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" vector-effect="non-scaling-stroke" />
												</svg>
											</button>
											<input type="text" value={state.quantity} class="store-product-form__input" readOnly />
											<button type="button" class="store-product-form__btn store-product-form__btn--increase" id="btn_increase" onClick={() => this.setState({ quantity: state.quantity + 1 })}>
												<svg viewBox="0 0 25 25" class="store-product-form__svg-icon">
													<line x1="6" y1="12.5" x2="19" y2="12.5" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" vector-effect="non-scaling-stroke" />
													<line y1="6" x1="12.5" y2="19" x2="12.5" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" vector-effect="non-scaling-stroke" />
												</svg>
											</button>
										</div>
									</div>
								</div>
								<div class="store-product-form__group">
									<button
										class="store-product-form__btn store-product__add-design-to-cart store-product-form__btn--submit"
										onClick={this.addToCart}
									>
										<CartIconAlt />
										add to cart
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}

	async addToCart(e: any) {
		e.preventDefault();
		const res = await addStoreProductToCart({
			name: this.state.name,
			alias: this.state.alias,
			flag: this.state.flag,
			number: this.state.number,
			design: this.props.storeProduct.id,
			size: this.state.size,
			quantity: this.state.quantity,
			options: { productTemplate: this.state.productTemplate },
		});
		if (res.error) await showAlert(res.error);
		if (res.id) location.href = '/cart';
	}

	changeSize(e: Event) {
		this.setState({ size: (e.target as HTMLSelectElement).value || undefined });
	}

	changeColor(e: Event) {
		this.setState({
			productTemplate: (e.target as HTMLSelectElement).value,
		});
	}
	
}
