import { PlayIcon } from './icons';

const SECTIONS = [
	{
		title: 'Step 2',
		subtitle: 'Toggle Recommended Colors',
		description: 'Once you\'ve uploaded your logo, the recommended colors, located in the top right workstation, will automatically change. They will now show color combinations using your logo\'s colors! This is a fast way to see all of our templates in various combinations. Quickly clicking through the different combos will give you the chance to see if any catch your eye.',
	},
	{
		title: 'Step 3',
		subtitle: 'Drag and Drop Color Sequence',
		description: 'Still don\'t like what you see? No problem! You can also experiment with your colors by clicking on the apparel workstation. By dragging and dropping the 5 colors into a new sequence you will instantly see all jerseys change as well. You can also paste a hex, hsl, or cmyk color value into the text input box or choose from one from our standard colors.',
	},
	{
		title: 'Step 4',
		subtitle: 'Choose Your Favorite Template & Add Text or Additional Logos',
		description: 'Once you\'ve decided on a template and color schematic you are ready to hone in on the design. You can now add additional logos and text. Keep in mind the "text" field is meant for text that will be applied to all jerseys on your roster. There will be separate fields for names and numbers.',
	},
	{
		title: 'Step 5',
		subtitle: 'Edit Personalization Fields',
		description: 'Once you\'ve finalized your base gaming jersey design, you can now add customization fields. These fields are unique to each player on your roster and are included in the price! You are able to add a country flag, player name, and number to the design. Keep in mind the purpose, at this stage of design, is to decide on placement, font, and color. You will be able to input specifics within the roster.',
	},
	{
		title: 'Step 6',
		subtitle: 'Edit Roster',
		description: 'Here you can enter the specifics for your team. GAMERTAG, number, flag, and size can all be submitted here.',
	},
	{
		title: 'Step 7',
		subtitle: 'Check out Other Products',
		description: 'Here\'s a slightly hidden feature; you are able to click on "Products" and view what other products look like in that template and colors. Scroll to the bottom and click "Save All". You can edit them one at a time later to make logo position and other changes.',
	},
	{
		title: 'Step 8',
		subtitle: 'Add to Cart and Receive Bulk Discounts',
		description: 'Once added to cart you will be able to see your team discount. For each additional jersey you purchase, the price per jersey is reduced. Prices are reduced more drastically at lower quantities. For example, the difference in price per jersey when going from one to two jerseys is much greater than going from 50 to 51 jerseys.',
	},
];

export const JerseyMakerLandingPage = () => {
	
	
	const handleVideoPlay = async (e: Event) => {
		const currentTarget = e.currentTarget as HTMLElement;
		const parent = currentTarget.parentNode as HTMLElement;
		await parent?.querySelector('video')?.play();
		
		currentTarget.classList.add('jersey-maker-landing__section-video-overlay--hidden');
	};
	
	const handleVideoStop = (e: Event) => {
		const currentTarget = e.currentTarget as HTMLElement;
		const parent = currentTarget.parentNode as HTMLElement;
		
		parent?.querySelector('.jersey-maker-landing__section-video-overlay')?.classList.remove('jersey-maker-landing__section-video-overlay--hidden');
	};

	return <div class="jersey-maker-landing">
		<div class="jersey-maker-landing__heading">
			<a class="jersey-maker-landing__heading-block" href="#maker">
				<span class="jersey-maker-landing__note">For esports jerseys, scroll down.</span>
				<img src="/static/jersey-maker/pw-vector.png" class="jersey-maker-landing__heading-img"/>
			</a>
			<a class="jersey-maker-landing__heading-block" href="https://teamseams.com">
				<span class="jersey-maker-landing__note">For sports jerseys, visit our sister site.</span>
				<img src="/static/jersey-maker/ts.png" class="jersey-maker-landing__heading-img" />
			</a>
		</div>
		
		<div class="jersey-maker-landing__hero" id="maker">
			<img src="/static/jersey-maker/hero.jpeg" alt="hero" class="jersey-maker-landing__hero-img"/>
		</div>
		
		<section class="jersey-maker-landing__section">
			<h2 class="jersey-maker-landing__section-title">Step 1</h2>
			<h3 class="jersey-maker-landing__section-subtitle">Upload your logo</h3>
			
			<p class="jersey-maker-landing__section-description">
				Once you upload your logo, our designer will extract the common colors from your logo and allow you to match the jersey to your logo colors.
			</p>
			
			<div class="jersey-maker-landing__section-row">
				<div class="jersey-maker-landing__section-block">
					<h2 class="jersey-maker-landing__section-title">Uploaded Logo</h2>
					<img src="/static/jersey-maker/uploaded-logo.png" alt="logo" class="jersey-maker-landing__section-img"/>
				</div>
				
				<div class="jersey-maker-landing__section-block">
					<h2 class="jersey-maker-landing__section-title">Uploaded Logo</h2>
					<img src="/static/jersey-maker/recommended-colors.jpeg" alt="logo" class="jersey-maker-landing__section-img"/>
				</div>
			</div>
		</section>
		
		{SECTIONS.map((section, index) => <section class="jersey-maker-landing__section">
			<h2 class="jersey-maker-landing__section-title">{section.title}</h2>
			<h3 class="jersey-maker-landing__section-subtitle">{section.subtitle}</h3>
				
			<p class="jersey-maker-landing__section-description">{section.description}</p>
			
			<div class="jersey-maker-landing__section-video-container">
				<div class="jersey-maker-landing__section-video-overlay" onClick={handleVideoPlay} />
				<div class="jersey-maker-landing__play-icon">
					<PlayIcon />
				</div>
				<video class="jersey-maker-landing__section-video" src={`/static/jersey-maker/video/${index}.mp4`} controls onPause={handleVideoStop} onEnded={handleVideoStop}/>
			</div>
		</section>)}
		
		<a href="/products" class="jersey-maker-landing__button">Try now</a>
	</div>;
};

