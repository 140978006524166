import { Component } from 'preact';
import {
	Maybe,
	ProductCategory,
	ProductCategoryDimension,
	PublicProduct,
	PublicProductOverride,
} from '../../common/data';
import { apply, findMatch, isSome, maybe } from '../../common/util';
import { Checkbox } from './checkbox';
import { FilterIcon, MinusIcon, PlusIcon, QuestionIcon } from './icons';
import { Popup } from './popup';
import { Context } from '../../common/context';
import { ProductCard } from './product-card';
import { ContentBlock } from './content';

const sortProductBy = {
	popularity: 'Popularity',
	relevance: 'Relevance',
	priceLow: 'Price: Low to High',
	priceHigh: 'Price: High to Low',
};
type SortType = keyof typeof sortProductBy;

export interface ProductPageProps {
	context: Context;
	products: PublicProduct[];
	categories: ProductCategory[];
	dimensions: ProductCategoryDimension[];
	filters: Record<string, string[]>;
	freeShipping: number;
}

export interface ProductPageState {
	filters?: Record<string, string[]>;
	collapsed?: string[];
	filtered: PublicProduct[];
	filterInfoPopup?: string;
	sidebarHide?: Boolean;
	hiddenClass?: string;
	filterCategoryPopup?: Boolean;
	sortProductBy: SortType;
	category?: Maybe<ProductCategory>;
}

export class ProductPage extends Component<ProductPageProps, ProductPageState> {
	
	constructor(props: ProductPageProps) {
		super(props);
		this.state = {
			filters: props.filters,
			filtered: [],
			sortProductBy: 'relevance',
		};
		this.handleFilterProduct(this.state.filters, true);
		this.sortProducts(this.state.sortProductBy, true);
	}
	
	componentDidMount() {
		this.handleFilterProduct(this.state.filters);
	}
	
	resetFilters = () => {
		this.handleFilterProduct();
	};
	
	toggleSidebar = () => {
		this.setState({
			...this.state,
			sidebarHide: !this.state.sidebarHide,
			hiddenClass: '',
		});
	};
	
	toggleFilter = (dim: string, it: string, needAdd: boolean) => {
		const filters = this.state.filters ? JSON.parse(JSON.stringify(this.state.filters)) : {};
		filters[dim] = [...(filters[dim] || []).filter((item: string) => !needAdd ? item !== it : item), ...(needAdd && !(filters[dim] || []).includes(it) ? [it] : [])];
		this.handleFilterProduct(filters);
	};
	
	toggleSection = (section: string) => () => {
		const collapsed = this.state.collapsed || [];
		if (collapsed.includes(section)) this.setState({ collapsed: [...collapsed.filter(sect => sect !== section)] });
		else this.setState({ collapsed: [...(collapsed || []), section] });
	};
	handleFilterProduct = (filters: Record<string, string[]> = {}, init = false) => {
		
		const { products, categories, context } = this.props;
		const dimensions = Object.keys(filters).filter(dim => filters[dim].length);
		
		const allCategories = new Set(dimensions.flatMap(dim => filters[dim]));
		const overrideMatches = (o: PublicProductOverride) => (
			!o.product_template
			&& (!o.category || allCategories.has(o.category))
		);
		
		const filtered: PublicProduct[] = products.filter(p => dimensions.every(
			dim => p.categories.some(cat => filters[dim].includes(cat))
		)).map(p => ({
			...p,
			priority: (p.category_priority ?? 0) + (p.overrides?.find(o => overrideMatches(o) && isSome(o.sort_priority))?.sort_priority ?? p.priority ?? 0),
		}));
		
		const single = dimensions.length == 1 && filters[dimensions[0]].length == 1 ? filters[dimensions[0]][0] : null;
		const serialized = dimensions.sort().map(dim => `${dim}=${filters[dim].sort()?.join(',')}`).join('&');
		const url = `/products${single ? `/${single}` : serialized ? `?${serialized}` : ''}`;
		const title = (allCategories.size == 1 ? apply([...allCategories][0], id => findMatch(categories, { id }))?.name + ' – ' : '') + context.domain.brand_name;
		if (!init && location.pathname + location.search != url) {
			document.title = title;
			history.replaceState({}, title, url);
		}
		
		const category = maybe(single, id => findMatch(categories, { id }));
		
		if (!init) this.setState({
			filtered,
			filters,
			category,
		}, () => this.sortProducts(this.state.sortProductBy));
		else this.state = {
			...this.state,
			filtered,
			filters,
			category,
		};
		
	};
	
	showFilterCategoryPopup = () => this.setState({ filterCategoryPopup: !this.state.filterCategoryPopup });
	hideFilterCategoryPopup = () => this.setState( { filterCategoryPopup: undefined });
	showFilterInfoPopup = (dimension: string) => this.setState({ filterInfoPopup: dimension });
	hideFilterInfoPopup = () => this.setState({ filterInfoPopup: undefined });
	
	sortProducts = (key: SortType, init = false) => {
	
		const compare = ({
			relevance: (a, b) => (b.priority || 0) - (a.priority || 0),
			popularity: (a, b) => (b.popularity || 0) - (a.popularity || 0),
			priceLow: (a, b) => Number(a.moq_price ?? a.price) - Number(b.moq_price ?? b.price),
			priceHigh: (a, b) => Number(b.moq_price ?? b.price) - Number(a.moq_price ?? a.price),
		} as Record<SortType, (a: PublicProduct, b: PublicProduct) => number>)[key];
		if (!compare) return console.error('Unsupported sort', key);
		
		const sorted = Array.from(this.state.filtered).sort(compare);
		
		if (!init) this.setState({ sortProductBy: key, filtered: sorted });
		else this.state = { ...this.state, filtered: sorted };
		
	};
	
	render({ products, dimensions, categories, freeShipping }: ProductPageProps, { filters, filtered, category, collapsed, filterInfoPopup, sidebarHide, hiddenClass, filterCategoryPopup }: ProductPageState) {
		
		const { toggleFilter, resetFilters, toggleSection } = this;
		
		const overrideCategories = categories.filter(cat => Object.keys(filters || {}).some(f => filters?.[f].includes(cat.id)));
		const isClient = typeof window == 'object';
		
		return <div class="products-page">
			<div class="products-page__header">
				{`< Free U.S. Shipping for orders $${freeShipping}+ >`}
			</div>
			<div className="products-page__nav">
				<button className="products-page__nav--btn products-page__nav--visible" onClick={ () => this.toggleSidebar()}>
					<span>{`${sidebarHide ? `Show` : `Hide`}`} Filters</span>
					<FilterIcon/>
				</button>
				<button className="products-page__nav--btn products-page__nav--filter" onClick={ () => this.showFilterCategoryPopup()}>
					<span>Filters</span>
					<FilterIcon/>
				</button>
				<span className="products-page__nav--btn products-page__nav--sortby">
					<select onChange={ (e) => this.sortProducts((e.target as HTMLSelectElement).value as SortType)}>
						{Object.entries(sortProductBy).map(([key, label]) => <option value={key} selected={this.state.sortProductBy === key}>{label}</option>)}
					</select>
				</span>
			</div>
			<div class="products-page__body">
				<div class={`products-page__filters ${sidebarHide ? `products-page__filters--d_none` : ``}`}>
					{dimensions?.map((dim, i) => [
						<div class={`products-page__filter-group ${collapsed?.includes(dim.id) ? 'products-page__filter-group--collapsed': ''}`}>
							<div class="products-page__filters-header">
								<button class="products-page__filter-group-btn" onClick={toggleSection(dim.id)}>{collapsed?.includes(dim.id) ? <PlusIcon /> : <MinusIcon />}</button>
								<h3 class="products-page__filter-group-title">{dim.name}</h3>
								{dim.description?.trim() && <button class="products-page__filter-group-info" onClick={() => this.showFilterInfoPopup(dim.id)}>
									<QuestionIcon />
								</button>}
								{!i && maybe(filters, fmap => !!Object.keys(fmap).length) && <button class="products-page__reset-filters" title="Reset filters" onClick={resetFilters}>Clear all</button>}
							</div>
							{categories.filter(cat => cat.dimension === dim.id).map(it => <Checkbox
								id={it.id}
								label={apply(it.name || it.id, txt => isClient ? txt : <a href={`/products?${dim.id}=${it.id}`}>{txt}</a>)}
								checked={filters?.[dim.id]?.includes(it.id)}
								onChange={(val:boolean) => toggleFilter(dim.id, it.id, val)}
							/>)}
						</div>,
						<hr />,
					])}
				</div>
				<div className="products-page__main">
					
					{category && <div class="products-page__cat-info">
						<h1>{category.name}</h1>
						{category.description && <ContentBlock item={category.description} />}
					</div>}
				
					<div class="products-page__products-block">
						{filtered?.map(p => <ProductCard
							product={p}
							key={p.id}
							overrideCategories={overrideCategories}
							domain={this.props.context.domain}
							categories={categories}
						/>)}
					</div>
					
					
				</div>
			</div>
			{maybe(filterCategoryPopup, did => <Popup close={this.hideFilterCategoryPopup}>
				{dimensions?.map((dim, i) => [
					<div class={`products-page__filter-group ${collapsed?.includes(dim.id) ? 'products-page__filter-group--collapsed': ''}`}>
						<div class="products-page__filters-header">
							<button class="products-page__filter-group-btn" onClick={toggleSection(dim.id)}>{collapsed?.includes(dim.id) ? <PlusIcon /> : <MinusIcon />}</button>
							<h3 class="products-page__filter-group-title">{dim.name}</h3>
							{dim.description?.trim() && <button class="products-page__filter-group-info" onClick={() => this.showFilterInfoPopup(dim.id)}>
								<QuestionIcon />
							</button>}
							{!i && maybe(filters, fmap => !!Object.keys(fmap).length) && <button class="products-page__reset-filters" title="Reset filters" onClick={resetFilters}>Clear all</button>}
						</div>
						{categories.filter(cat => cat.dimension === dim.id).map(it => <Checkbox id={it.id} label={it.name || it.id} checked={filters?.[dim.id]?.includes(it.id)} onChange={(val:boolean) => toggleFilter(dim.id, it.id, val)}/>)}
					</div>,
					<hr />,
				])}
			</Popup>)}
			{maybe(filterInfoPopup, did => <Popup close={this.hideFilterInfoPopup}>
				<div class="products-page__info-popup-body">
					{findMatch(dimensions, { id: did })!.description?.trim().split(/\n+/).map(p => <p>{p}</p>)}
				</div>
			</Popup>)}
		</div>;
	}
}

