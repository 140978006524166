import { Component } from 'preact';
import { ContentBlock, ContentContext } from './content';
import { Context } from '../../common/context';
import { ContentExtras, Page } from '../../common/data/content';

export interface PageWrapperProps {
	context: Context;
	page: Page;
	extras: ContentExtras;
}
interface PageWrapperState {}

export class PageWrapper extends Component<PageWrapperProps, PageWrapperState> {

	render() {
		const { context, page, extras } = this.props;
		const { domain } = context;
		return <div class="page">
			<ContentContext.Provider value={{
				target: ['web'],
				domain,
				website: `https://${context.domain}`,
				extras,
				templates: {}, // TODO page templates, if any
				link: s => s,
				image: s => s,
			}}>
				<ContentBlock item={page.content} />
			</ContentContext.Provider>
		</div>;
	}

}
