import { unsubscribeByEmail } from '../lib/api';
import { useState } from 'preact/hooks';
import { getEventValue } from '../lib/util';
import { showAlert } from './popup';
import { Maybe } from '../../common/data';

export const Unsubscribe = ({ email, contentId }: {
	email: number;
	contentId: string;
}) => {
	
	const [unsubscribed, setUnsubscribed] = useState(false);
	const [feedback, setFeedback] = useState<Maybe<string>>(null);
	
	const handleUnsubscribeButtonClick = async () => {
		const { error, success } = await unsubscribeByEmail({ email, contentId, feedback });
		
		if (error) await showAlert(error);
		if (success) setUnsubscribed(true);
	};
	
	return <div class="unsubscribe-page">
		{!unsubscribed && <>
			<h1 class="unsubscribe-page__title">Please confirm unsubscribing from our newsletter and marketing emails.</h1>
			<textarea class="unsubscribe-page__textarea" rows={10} placeholder="If you have a moment, please tell us why you chose to unsubscribe, or anything else we should know." value={feedback ?? ''} onInput={e => setFeedback(getEventValue(e)?.trim() || null)} />
			<button type="button" class="unsubscribe-page__button" onClick={handleUnsubscribeButtonClick}>Unsubscribe</button>
		</>}
		{unsubscribed && <span class="unsubscribed-page__success">You have successfully unsubscribed from our newsletter and marketing emails. You will still receive service emails about your account or any active orders.</span>}
	</div>;
};

