import { en } from './lang/en';
import { Context } from './context';

export type TranslationMap = typeof en;

export type TranslationExtractor = (arg: TranslatedString | string) => string;

export type TranslationId = keyof TranslationMap;
export type LanguageId = 'en';

export type Translated<T> = { [key in LanguageId]: T };
export type TranslatedString = Translated<string>;

export const defaultLanguage = 'en';
export const languages: LanguageId[] = ['en'];
export const languageNames: { [key in LanguageId]: string } = { en: 'English' };

export const translations: { [key in LanguageId]: { [key in TranslationId]: TranslationMap[key] }} = { en };

function langFromThis(that: Context | LanguageId): LanguageId {
	return typeof that == 'string' ? that : (that && that.lang) ? that.lang : defaultLanguage;
}

export function getTranslation<
	Id extends TranslationId
>(this: Context | LanguageId, id: Id, ...args: any[]) {
	const lang = langFromThis(this);
	const val = translations[lang][id] || translations[defaultLanguage][id] || id;
	if (typeof val != 'function') return val;
	return (val as any)(...args);
}
export type TranslateHandler = <
	Id extends TranslationId,
	Val extends TranslationMap[Id],
	Args extends (Val extends ((...args: any) => any) ? Parameters<Val> : []) = any,
>(
	id: Id,
	...args: Args
) => (
	Val extends (...args: any) => any ? ReturnType<Val> : Val
);

export function str(this: Context | LanguageId, arg: TranslatedString | string) {
	return typeof arg == 'string' ? arg : arg[langFromThis(this)] || arg.en;
}
