import { useState } from 'preact/hooks';
import { Maybe } from '../../common/data';
import { ArrowLeft2Icon, ArrowRight2Icon } from './icons';

interface CarouselProps {
	images: string[];
}
export const Carousel = ({ images }: CarouselProps) => {
	
	const [selected, setSelected] = useState<Maybe<string>>(images?.[0]);
	
	const [isLoading, setLoading] = useState(false);
	const getPreviewClass = (photo: string) => selected === photo ? 'carousel__preview carousel__preview--selected' : 'carousel__preview';
	
	const applyImageWithAnimation = (index: number) => {
		if (!isLoading) {
			setLoading(true);
			setTimeout(() => {
				setLoading(false);
				setSelected(images[index]);
			}, 350);
		}
	};
	
	const switchImage = (dir: -1|1) => {
		const index = images.indexOf(String(selected));
		const isSelectedFirst = index === 0;
		const isSelectedLast = index === images.length - 1;
		if ((dir === -1 && isSelectedFirst) || (dir === 1 && isSelectedLast)) {
			return;
		}
		
		applyImageWithAnimation(index + dir);
	};
	
	return <div class="carousel">
		<div class="carousel__big-pic">
			<ArrowLeft2Icon class={`carousel__arrow ${images.indexOf(String(selected)) === 0 ? 'carousel__arrow--disabled' : ''}`} onClick={() => switchImage(-1)} />
			{selected && <img class={`carousel__image ${isLoading ? 'carousel__image--loading' : ''}`} src={selected} />}
			<ArrowRight2Icon class={`carousel__arrow ${images.indexOf(String(selected)) === images.length - 1 ? 'carousel__arrow--disabled' : ''}`} onClick={() => switchImage(1)} />
		</div>
		<div class="carousel__preview-list">
			{images?.map((image, index) => <div className={getPreviewClass(image)} onClick={() => applyImageWithAnimation(index)}>
				<img src={image} alt="preview" />
			</div>)}
		</div>
	</div>;
};
