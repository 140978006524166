import { Component } from 'preact';
import { Maybe, PublicDomain, PublicProduct } from '../../common/data';
import { addDesignPackageToCart, uploadArtwork } from '../lib/api';
import { Input } from './input';
import { formatPrice } from '../../common/util';
import { getUploadPreviewURL } from '../lib/images';
import { FileUploadIcon } from './icons';
import { get } from 'dtk';
import { showAlert } from './popup';
import { Upload } from '../../common/data/designs';
import { Carousel } from './carousel';
import { ProductCard } from './product-card';

export interface DesignPackagePageProps {
	packages: PublicProduct[];
	products: PublicProduct[];
	topChoices: PublicProduct[];
	selected?: string;
	domain: PublicDomain;
}

interface DesignPackagePageState {
	product: PublicProduct;
	logo?: Upload;
	selectedProducts?: Record<string, boolean>;
	comment?: string;
}

const images_top = ['/static/design-package/blue.png', '/static/design-package/am.png', '/static/design-package/squirtle.png', '/static/design-package/9th.png', '/static/design-package/s.png'];
const images = ['/static/design-package/blue.png', '/static/design-package/yellow.png', '/static/design-package/purple.png', '/static/design-package/salmon.png', '/static/design-package/any.png', '/static/design-package/red.png'];
const stores = [
	{ link: '/store/AquatikEsports', img: '/static/design-package/aquatik-store.png' },
	{ link: '/store/gophercon', img: '/static/design-package/gophercon-store.png' },
	{ link: '/store/galacticorder', img: '/static/design-package/galactic-store.png' },
];
export class DesignPackagePage extends Component<DesignPackagePageProps, DesignPackagePageState> {

	packagesEl: Maybe<HTMLElement> = null;
	
	constructor(props: DesignPackagePageProps) {
		super(props);
		this.state = {
			product: props.selected ? props.packages.find(p => p.id == props.selected)! : props.packages[0],
		};
	}
	
	async changePckg(product: PublicProduct, scroll?: boolean) {
		history.replaceState({}, document.title, `/design-package/${product.id}`);
		await this.setState({ product });
		
		if (scroll && this.packagesEl) {
			scrollTo({ top: this.packagesEl.offsetTop, behavior: 'smooth' });
		}
	}

	render(props: DesignPackagePageProps, state: DesignPackagePageState) {
		const starterPack = props.packages.find(p => p.id == 'dpkg0')!;
		
		const productsIncluded = this.state.product.features?.productsIncluded ?? 5;
		const allProducts = productsIncluded == 'all';
		const maxSelected = allProducts ? false : Object.values(this.state.selectedProducts || {}).filter(v => v).length >= productsIncluded;
		
		const getProductsText = () => {
			if (allProducts) return <p class="design-package__products-text">This design package includes custom designs for all current products.</p>;
			if (!productsIncluded) return <p class="design-package__products-text">This design package does not include product designs.</p>;
			return <p class="design-package__products-text">Choose {productsIncluded > 1 ? `up to ${productsIncluded} products` : 'the product'} you would like us to design for you.</p>;
		};
		
		const haveAdditionalTopInfo = props.domain.id === 'pw';
	
		return <div class="design-package">
			
			{haveAdditionalTopInfo && <>
				<section class="design-package__section">
					<h2 className="design-package__title">Custom design packages</h2>
					<h3 className="design-package__subtitle">Custom design your own unique e-sports jersey and other apparel
					through our unique and powerful online customizer.</h3>
				
					<h2 className="design-package__title design-package__title--position-right">Starter package | $80</h2>
					<div className="design-package__row">
						<Carousel images={images_top}/>
						<div className="design-package__starter-pack">
							<h3 className="design-package__subtitle">Your jersey will be ready to buy or sell.</h3>
							<ul className="design-package__list">
								{[
									'Initial Concept',
									'2 Revisions',
									'3 Day Delivery',
									'Unlimited Colors*',
									'Front & Back',
									'Photo Realistic Render',
									'Free Store Hosting',
									'Fundraising',
									'Many More Products',
									'Bulk Discounts',
								].map(item => <li className="design-package__list-item">{item}</li>)}
							</ul>
							<button className="design-package__add-to-cart" onClick={() => this.changePckg(starterPack, true)}>Order now
							</button>
						</div>
					</div>
				
					<h3 className="design-package__subtitle design-package__subtitle--right">Stand out from the crowd and unite your gaming team with
					high-quality, customizable apparel that get attention everywhere they go!</h3>
				
					<ul className="design-package__list design-package__list--right">
						{[
							'100% polyester with moisture-wicking technology.',
							'Full dye sublimated cut-and-sew designs with set-in sleeves.',
							'FREE Shipping & Handling',
						].map(item => <li className="design-package__list-item">{item}</li>)}
					</ul>
				</section>
			
				<section className="design-package__section">
					<h2 className="design-package__title">unlimited colors</h2>
					<h3 className="design-package__subtitle">Your design will be uploaded to the platform so that you can change the colors without limits.</h3>
					<Carousel images={images} />
				</section>
				<section className="design-package__section">
					<h2 className="design-package__title">all paid packages include logo isolation and vector treatment.</h2>
			
					<img class="design-package__raster-to-vector" src="/static/design-package/raster-to-vector.png" alt="raster to vector" />
				
				</section>
				<section className="design-package__section">
			
					<h2 className="design-package__title">top jersey choices</h2>
			
					<div className="products-page__products-block">
						{props.topChoices?.map(product => <ProductCard product={product} overrideCategories={[]} domain={props.domain} categories={[]} />)}
					</div>
				</section>
				<section className="design-package__section">
					<h2 className="design-package__title">store set up with custom web link</h2>
			
					<div class="design-package__stores">
						{stores.map(store => <a href={store.link}><img src={store.img} alt={store.link} /></a>)}
					</div>
				</section>
			</>}
			
			<div class="design-package__options" ref={it => this.packagesEl = it}>
				{props.packages.map(p => <div class={`design-package__option ${state.product.id == p.id ? 'design-package__option--selected' : ''}`} onClick={() => this.changePckg(p)}>
					<div class="design-package__option-name">
						<p class="design-package__option-title">{p.name}</p>
						<p class="design-package__option-description">{p.description}</p>
						<strong class="design-package__option-price">{formatPrice(p.price!)}</strong>
						<button class={`design-package__option-btn ${state.product.id == p.id ? 'design-package__option-btn--selected' : ''}`}>Select</button>
					</div>
				</div>)}
			</div>
			<div class="design-package__attachment">

				<h2>1. Upload your logo</h2>
				<input class="design-package__attachment-upload" id="upload" type="file" onChange={e => this.uploadLogo(e.target as HTMLInputElement)} />

				<div class="design-package__attachment-wrapper" onClick={() => this.openDialogBox()}>
					<FileUploadIcon/>
					<p>{state.product.id == 'vector' ? 'Upload an image in PNG (recommended) or JPEG format.' : 'Upload an image in AI, SVG, EPS, PNG or JPEG format.'}</p>
				</div>
				<div class="design-package__attachment-preview">
					{state.logo ? <img class="design-package__logo" src={getUploadPreviewURL(state.logo)} alt="logo" /> : <p class="design-package__attachment-info">Uploaded Logo Will Show Here.</p>}
				</div>
			</div>
			<div class="design-package__products">
				<h2>2. Choose the products</h2>

				{getProductsText()}

				<div class="design-package__items">
					{props.products.map(p => <label>
						<input type="checkbox"
							checked={state.selectedProducts?.[p.id] || allProducts}
							disabled={(maxSelected && !state.selectedProducts?.[p.id]) || allProducts}
							onChange={e => this.setState({ selectedProducts: {
								...state.selectedProducts,
								[p.id]: (e.target as HTMLInputElement).checked,
							} })}
						/>
						{p.name}
					</label>)}
				</div>
			</div>
			
			<div class="design-package__specification">
				<h2>3. Enter comments</h2>

				<div class="design-package__comment">
					<Input textarea={true} value={state.comment} handler={comment => this.setState({ comment })} attributes={{ placeholder: 'Enter any specific requests for our designers.' }} />
				</div>
			</div>
			
			<button class="design-package__add-to-cart" type="button" onClick={() => this.addToCart()}>
				Add to cart
			</button>
			
		</div>;
		
	}

	async uploadLogo(el: HTMLInputElement) {
		
		const file = el.files?.[0];
		if (!file) return;
		
		const res = await uploadArtwork({ file });
		if (res.error) await showAlert(res.error);
		if (res.upload) this.setState({ logo: res.upload });
		const page = get('.products-page')!;
	}

	openDialogBox() {
		const page = get('.design-package__attachment-upload')!;
		page.click();
	}
	
	async addToCart() {
	
		const upload = this.state.logo?.id;
		const comment = this.state.comment;
		
		if (!upload && !comment) return await showAlert('Please upload a logo or enter a comment', 'warning');
		
		const res = await addDesignPackageToCart({
			product: this.state.product.id,
			upload,
			comment,
			products: Object.entries(this.state.selectedProducts || {}).filter(e => e[1]).map(e => e[0]),
		});
		
		if (res.error) await showAlert(res.error);
		if (res.id) location.href = '/cart';
	
	}

}
