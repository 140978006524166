import { Context } from '../../common/context';
import { ComponentFactory, h, render } from 'preact';
import { initLandingPage } from './landing';
import { RestoreForm, SetPasswordForm, SignInForm, SignUpForm } from '../components/auth';
import { get } from 'dtk';
import { SettingsEditor } from '../components/dashboard/settings';
import { StoreProductPage } from '../components/store-product';
import { GiftcardOrderPage } from '../components/giftcards';
import { DesignPackagePage } from '../components/design-package';
import { DashboardPage } from '../components/dashboard';
import { CommissionsPage } from '../components/commissions';
import { StorePage } from '../templates/pages/store';
import { FaqPage } from '../components/faq-page';
import { ProductPage } from '../components/product-page';
import { ShoppingCart } from '../components/cart/cart';
import { PageWrapper } from '../components/page';
import { Unsubscribe } from '../components/unsubscribe';
import { LandingPage } from '../components/landing-page';
import { JerseyMakerLandingPage } from '../components/jersey-maker-landing';


export type PageHandler = (c: Context, data: any) => void;

const component = (cls: ComponentFactory<any>, selector: string) => (c: Context, data: any) => {
	const it = get(selector)!;
	render(h(cls, { context: c, ...data }), it.parentElement!, it);
};

type ComponentExportKey<M extends object> = { [K in keyof M]: M[K] extends ComponentFactory<any> ? K : never }[keyof M];

const lazyComponent = <M extends object, K extends ComponentExportKey<M>> (loader: () => Promise<M>, key: K, selector: string) => async (c: Context, data: any) => {
	const module = await loader();
	const cls = module[key] as ComponentFactory<any>;
	const it = get(selector)!;
	render(h(cls, { context: c, ...data }), it.parentElement!, it);
};

export const pageHandlerMap = {
	landing: initLandingPage,
	landingPw: component(LandingPage, '.landing'),
	signIn: component(SignInForm, '.auth'),
	signUp: component(SignUpForm, '.auth'),
	restore: component(RestoreForm, '.auth'),
	setPassword: component(SetPasswordForm, '.auth'),
	builder: async (c: Context, data: any) => {
		const { initBuilderPage } = await import('../components/builder/builder');
		initBuilderPage(c, data);
	},
	// order: component(OrderPage, '.order'), // maybe eventually
	products: component(ProductPage, '.products-page'),
	settings: component(SettingsEditor, '.settings'),
	faq: component(FaqPage, '.faq'),
	commissions: component(CommissionsPage, '.commissions'),
	store: component(StorePage, '.store'),
	storeProductPage: component(StoreProductPage, '.store-product'),
	giftcards: component(GiftcardOrderPage, '.giftcard-order'),
	designPackage: component(DesignPackagePage, '.design-package'),
	orderItemPreview: lazyComponent(() => import('../components/order-item-preview'), 'OrderItemPreviewPage', '.order-item-preview'),
	digitizeOrderItem: lazyComponent(() => import('../components/digitize-order-item'), 'DigitizeOrderItemPage', '.digitize-order-item'),
	dashboard: lazyComponent(() => import('../components/dashboard'), 'DashboardPage', '.dashboard-page'),
	shoppingCart: lazyComponent(() => import('../components/cart/cart'), 'ShoppingCart', '.cart'),
	page: component(PageWrapper, '.page'),
	previewRenderer: async () => {
		const { initLoopbackPreviewRenderer } = await import('../components/preview-renderer');
		initLoopbackPreviewRenderer();
	},
	unsubscribe: component(Unsubscribe, '.unsubscribe-page'),
	jerseymaker: component(JerseyMakerLandingPage, '.jersey-maker-landing'),
};

export type PageHandlerId = keyof typeof pageHandlerMap;

export const pageHandlers: {
	[key in PageHandlerId]: PageHandler
} = pageHandlerMap;
