import { Component } from 'preact';
import type { FaqCategory, FaqItem, ProductionTimeEntry, PublicDomain, PublicProduct } from '../../common/data';
import { MinusIcon, PlusIcon } from './icons';
import { useState } from 'preact/hooks';
import { SizeChartTable } from './size-chart-table';
import { getProductionTimes, getProductsWithSizeCharts } from '../lib/api';
import { ContentBlock, ContentContext } from './content';
import { ContentExtras } from '../../common/data/content';

function SizeChartFaqTableItem({ product } : { product: PublicProduct }) {
	const [open, toggle] = useState(false);
	
	return <div class={`faq-item faq-item--${open ? 'open' : 'closed'}`}>
		<h3 onClick={() => toggle(!open)}>{product.name} {open ? <MinusIcon/> : <PlusIcon/>}</h3>
		{open && <SizeChartTable product={product} />}
	</div>;
}

export class SizeChartFaqTable extends Component<{}, {products: PublicProduct[]}> {
	
	async componentDidMount() {
		const { products } = await getProductsWithSizeCharts();
		await this.setState({ products });
	}
	
	render() {
		return <div>{this.state.products?.map((pr: PublicProduct) => <SizeChartFaqTableItem product={pr} />)}</div>;
	}
}

export class ProductionTimeFaqTable extends Component<any, { prodTimes: ProductionTimeEntry[] } > {
	
	async componentDidMount() {
		await this.setState({
			...await getProductionTimes(),
		});
	}
	
	render() {
		return <div>
			<table>
				<tbody>
					<tr>
						<th>Name</th>
						<th>Time</th>
					</tr>
					{this.state.prodTimes?.map(time => <tr>
						<td>{time.name}</td>
						<td>{time.min_prod_days} - {time.max_prod_days} days</td>
					</tr>)}
				</tbody>
			</table>
		</div>;
	}
}

interface FaqPageProps {
	domain: PublicDomain;
	items: FaqItem[];
	categories: FaqCategory[];
	extras: ContentExtras;
}

export class FaqPage extends Component<FaqPageProps> {
	
	render({ items, categories, domain, extras } : FaqPageProps) {
		
		return <div class="faq">
			<div class="faq__container">
				<ContentContext.Provider value={{
					target: ['web'],
					domain,
					website: `https://${domain.host}`,
					extras,
					templates: {},
					link: s => s,
					image: s => s,
				}}>
					{categories.map(cat => <div class="faq__section">
						<h2>{cat.name}</h2>
						<div class="faq__body">
							{items?.filter(it => it.category === cat.id).map(it => <FaqItemComponent item={it} />)}
						</div>
					</div>)}
				</ContentContext.Provider>
			</div>
		</div>;
	}

}

export interface FaqItemComponentProps {
	item: FaqItem;
}

export interface FaqItemComponentState {
	open: boolean;
}

class FaqItemComponent extends Component<FaqItemComponentProps, FaqItemComponentState> {
	
	render(props: FaqItemComponentProps, state: FaqItemComponentState) {
		return <div class={`faq-item faq-item--${state.open ? 'open' : 'closed'}`}>
			<h3 onClick={() => this.setState({ open: !state.open })}><span>{props.item.question}</span> {state.open ? <MinusIcon/> : <PlusIcon/>}</h3>
			{state.open && <ContentBlock item={props.item.answer} />}
		</div>;
	}
	
}
